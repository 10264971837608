/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "antd/dist/antd.css"
import "swiper/swiper.scss"

// import FacebookMessengerChat from "./src/components/FacebookChatMessenger"

const wrapPageElement = ({ element }) => (
  <>
    {element}
    {/* <FacebookMessengerChat /> */}
  </>
)

const onRouteUpdate = () => {
  const headerElement = document.getElementById("header")
  if (headerElement) {
    headerElement.scrollIntoView()
  }
}

export { wrapPageElement, onRouteUpdate }
